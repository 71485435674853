import React, { useLayoutEffect, useState } from "react";
import SEO from "@components/SEO";
import Navbar from "@components/Navbar";
import Footer from "@components/Footer";

import { GreenButton } from "@components/GreenButton";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";

import homeStyles from "@cssmodules/home.module.scss";

export default function Layout({ children }) {
    const { pathname, search } = useLocation();
    const [showContactBtn, setShowContactBtn] = useState(false);


    useLayoutEffect(() => {
        const onScroll = () => {
            if(pathname.split("/")[1] === "") {
                if (window.scrollY > window.innerHeight) {
                    setShowContactBtn(true);
                } else {
                    setShowContactBtn(false);
                }
            }
            else {
                if(!showContactBtn) {
                    setShowContactBtn(true);
                }
            }
        };

        onScroll();

        window.addEventListener("scroll", onScroll);
        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, []);

    

    return (
        <div id="layout-container">
            <SEO />
            <Navbar />
            {children}
            <GreenButton
                    as={Link}
                    to={`/hubungi-kami/${search}`}
                    className={`${homeStyles.floatingContactUs} ${
                        showContactBtn ? `${homeStyles.show}` : ``
                    }`}
                >
                    HUBUNGI KAMI DISINI!
                    <img
                        src={require("../assets/images/icons/icon-09.svg")}
                        className={homeStyles.waIcon}
                        alt="whatsapp"
                    />
                </GreenButton>
            <Footer />
        </div>
    );
}
