export const router = [
    {
        path: "/",
        label: "Home",
    },
    {
        path: "/klien-kami/",
        label: "Klien Kami",
    },
    {
        path: "/portofolio/",
        label: "Portofolio",
    },
    {
        path: "/layanan-gratis/",
        label: "Layanan Gratis",
    },
    {
        path: "/cara-order/",
        label: "Cara Order",
    },
    {
        path: "/standar-ukuran/",
        label: "Standar Ukuran",
    },
    {
        path: "/hubungi-kami/",
        label: "Hubungi Kami",
    },
    {
        path: "/contact/",
        label: "Contact",
    },
];
